// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-2019-bronx-jsx": () => import("/opt/build/repo/src/pages/2019-bronx.jsx" /* webpackChunkName: "component---src-pages-2019-bronx-jsx" */),
  "component---src-pages-2019-brooklyn-bat-jsx": () => import("/opt/build/repo/src/pages/2019-brooklyn-bat.jsx" /* webpackChunkName: "component---src-pages-2019-brooklyn-bat-jsx" */),
  "component---src-pages-2019-brooklyn-prospect-park-jsx": () => import("/opt/build/repo/src/pages/2019-brooklyn-prospect-park.jsx" /* webpackChunkName: "component---src-pages-2019-brooklyn-prospect-park-jsx" */),
  "component---src-pages-2019-brooklyn-sunset-park-jsx": () => import("/opt/build/repo/src/pages/2019-brooklyn-sunset-park.jsx" /* webpackChunkName: "component---src-pages-2019-brooklyn-sunset-park-jsx" */),
  "component---src-pages-2019-manhattan-jsx": () => import("/opt/build/repo/src/pages/2019-manhattan.jsx" /* webpackChunkName: "component---src-pages-2019-manhattan-jsx" */),
  "component---src-pages-2019-queens-jsx": () => import("/opt/build/repo/src/pages/2019-queens.jsx" /* webpackChunkName: "component---src-pages-2019-queens-jsx" */),
  "component---src-pages-2019-staten-island-jsx": () => import("/opt/build/repo/src/pages/2019-staten-island.jsx" /* webpackChunkName: "component---src-pages-2019-staten-island-jsx" */),
  "component---src-pages-404-jsx": () => import("/opt/build/repo/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("/opt/build/repo/src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-bio-jsx": () => import("/opt/build/repo/src/pages/bio.jsx" /* webpackChunkName: "component---src-pages-bio-jsx" */),
  "component---src-pages-contact-jsx": () => import("/opt/build/repo/src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/build/repo/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-manifesto-jsx": () => import("/opt/build/repo/src/pages/manifesto.jsx" /* webpackChunkName: "component---src-pages-manifesto-jsx" */),
  "component---src-pages-past-jsx": () => import("/opt/build/repo/src/pages/past.jsx" /* webpackChunkName: "component---src-pages-past-jsx" */),
  "component---src-pages-pipp-jsx": () => import("/opt/build/repo/src/pages/pipp.jsx" /* webpackChunkName: "component---src-pages-pipp-jsx" */),
  "component---src-pages-resources-jsx": () => import("/opt/build/repo/src/pages/resources.jsx" /* webpackChunkName: "component---src-pages-resources-jsx" */),
  "component---src-pages-upcoming-jsx": () => import("/opt/build/repo/src/pages/upcoming.jsx" /* webpackChunkName: "component---src-pages-upcoming-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

